import ApiService from "@/core/services/ApiService";
export const getReportes = () => {
  return ApiService.get(`${process.env.VUE_APP_MICROSERVICE_API_REPORTES}`);
};
export const getReportesId = (_id) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_REPORTES}/${_id}`
  );
};
export const crearReporte = (payload = {}) => {
  return ApiService.post(
    `${process.env.VUE_APP_MICROSERVICE_API_REPORTES}`,
    payload
  );
};
export const actualizarReporte = (_id, payload = {}) => {
  return ApiService.put(
    `${process.env.VUE_APP_MICROSERVICE_API_REPORTES}/${_id}`,
    payload
  );
};
export const eliminarReporte = (_id) => {
  return ApiService.delete(
    `${process.env.VUE_APP_MICROSERVICE_API_REPORTES}/${_id}`
  );
};
export const downloadReportePdf = (_id) => {
  return ApiService.query(
    `${process.env.VUE_APP_MICROSERVICE_API_REPORTES}/download/${_id}`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
};
export default {
  getReportes,
  crearReporte,
  actualizarReporte,
  eliminarReporte,
  downloadReportePdf,
};
